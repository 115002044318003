import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, Link, useStaticQuery } from 'gatsby'

// styles
import './sectionBlogPost.scss'

const Blog = () => {
  const data = useStaticQuery(
    graphql`{
  allContentfulBlog(sort: {fields: date, order: DESC}, limit: 3) {
    edges {
      node {
        slug
        title
        date
        card {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        category {
          type
        }
      }
    }
  }
}
`
  )
  const mouths = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ]

  const CardBlog = (props) => {
    return <>
      <div className='sizeimg-post-blog'>
        <GatsbyImage image={props.image} alt={props.slug} className='w-100 h-100' />
      </div>
      <div className='position-absolute d-flex justify-content-center alignt-items-center flex-column text-center text-uppercase circle-blog'>
        <p className='text-capitalize'>{props.date}</p>
        <p className='text-uppercase'>{props.month}</p>
      </div>
      <div className='caption-blog'>
        <p className='font-weight-bold pt-3 pb-0'>{props.type}</p>
        <h4 className='pb-1'>{props.title}</h4>
        <Link to={`/blog/${props.slug}/`} className='btn btn-blog d-flex justify-content-center align-items-center text-center text-uppercase'>
          Leia Mais
        </Link>
      </div>
    </>;
  }

  const CardBlogMobile = (props) => {
    return <>
      {props.index === 0 &&
        <div className='col-xl-4 col-lg-6 pb-4 d-block d-sm-none' key={props.index}>
          <CardBlog
            slug={props.slug}
            image={props.image}
            date={props.date}
            month={props.month}
            type={props.type}
            title={props.title}
          />
        </div>
      }

      {props.index !== 0 &&
        <div className='col-xl-4 col-lg-6 pb-4 d-flex d-sm-none justify-content-between' key={props.index}>
          <div className='col-6 col-sm-12 p-0 sizeimg-post-blogMobile'>
            <GatsbyImage image={props.image} alt={props.slug} className='w-100 h-100' />
            <div className='position-absolute d-flex justify-content-center alignt-items-center flex-column text-center text-uppercase circle-blogMobile'>
              <p className='text-capitalize'>{props.date}</p>
              <p className='text-uppercase'>{props.month}</p>
            </div>
          </div>
          <div className='caption-blogMobile col-6 col-sm-12 pl-3 p-0'>
            <p className='font-weight-bold pt-0 pt-sm-3 pb-0'>{props.type}</p>
            <h4 className='pb-1 mytext'>{props.title}</h4>
            <Link to={`/blog/${props.slug}/`} className='btn btn-blog d-flex justify-content-center align-items-center text-center text-uppercase w-100'>
              Leia Mais
            </Link>
          </div>
        </div>
      }
    </>;
  }

  return <>
    <div className='pt-4 pb-4'>
      <div className='container '>
        <div className='row'>
          <div className='col-12 header-blog'>
            <h2>Blog Do Spani</h2>
            <p>Fique por dentro de tudo sobre nós</p>
          </div>
        </div>
        <div className='row pt-4'>
          {
            data.allContentfulBlog.edges.map((post, index) => {
              const date = new Date(post.node.date)
              return <>
                <div className='col-xl-4 col-lg-6 pb-4 d-none d-sm-block' key={index}>
                  <CardBlog
                    slug={post.node.slug}
                    image={post.node.card.localFile.childImageSharp.gatsbyImageData}
                    date={date.getDate()}
                    month={mouths[date.getMonth()]}
                    type={post.node.category.type}
                    title={post.node.title}
                    className='d-none d-sm-block'
                  />
                </div>
                <CardBlogMobile
                  index={index}
                  slug={post.node.slug}
                  image={post.node.card.localFile.childImageSharp.gatsbyImageData}
                  date={date.getDate()}
                  month={mouths[date.getMonth()]}
                  type={post.node.category.type}
                  title={post.node.title}
                  className='d-flex d-sm-none'
                />
              </>;
            })
          }
        </div>
      </div>
    </div>
  </>;
}
export default Blog
