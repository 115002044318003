import React, { useEffect, useRef, useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout/index'
import SectionBlogPost from '../../components/SectionBlogPost/sectionBlogPost.js'
import locationPin from '../../images/icons/header/location-pin.svg'
import '../../pages/styles/ofertas.scss'
import Helmet from 'react-helmet'
import { GatsbyImage } from "gatsby-plugin-image";
import iconvisualizar from '../../images/ofertas/iconvisualizar.svg'
import icondownload from '../../images/ofertas/icondownload.svg'
import FileDownload from 'js-file-download'
import Axios from 'axios'

const Offer = ({ data }) => {
  const isExists = () => data.allContentfulTabloide.edges.length !== 0
  console.log(data)
  const [currentMarket, setCurrentMarket] = useState(typeof window !== 'undefined' ? JSON.parse(window.localStorage.getItem('currentMarket')) : null)
  const modalRef = useRef()
  const date = isExists() && new Date(data.allContentfulTabloide.edges[0].node.dataFim)
  const offers = data.allContentfulTabloide
  const lojas = data.contentfulLojas

  useEffect(() => {
    window.localStorage.setItem('OFERTAS_SEMANA', lojas.slug)
  }, [])
  const breadC = {
    visible: true,
    items: [
      { path: '/', name: 'Home' },
      { path: '/ofertas/', name: 'Ofertas' }
    ]
  }

  function handleDownload (url, fileName) {
    Axios({
      url: url,
      method: 'GET',
      responseType: 'blob' // Important
    }).then((response) => {
      FileDownload(response.data, fileName)
    })
  }

  function getDate () {
    var dia = date.getDate().toString()
    var diaF = (dia.length === 1) ? '0' + dia : dia
    var mes = (date.getMonth() + 1).toString() // +1 pois no getMonth Janeiro começa com zero.
    var mesF = (mes.length === 1) ? '0' + mes : mes
    var anoF = date.getFullYear()
    return diaF + '/' + mesF + '/' + anoF
  }

  const localBusiness = {
    '@context': 'http://www.schema.org',
    '@type': 'Store',
    name: `${lojas.nome} | Spani Atacadista`,
    url: `https://www.spani.com.br/lojas/${lojas.slug}`,
    image: 'https://www.spani.com.br/card-spani.png',
    description: `Ofertas válidas para a loja ${lojas.logradouro} - ${lojas.municipio} - ${lojas.estado}.`,
    telephone: lojas.telefone,
    address: {
      '@type': 'PostalAddress',
      streetAddress: lojas.logradouro,
      addressLocality: lojas.municipio,
      addressRegion: lojas.estado,
      postalCode: lojas.cep,
      addressCountry: lojas.estado
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: lojas.lat,
      longitude: lojas.lng
    },
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday'
        ],
        opens: lojas.diaUtilAbre,
        closes: lojas.diaUtilFecha
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'Sunday',
        opens: lojas.domingoAbre,
        closes: lojas.domingoFecha
      }
    ],
    publisher: {
      '@type': 'Organization',
      name: 'Spani Atacadista'
    }
  }

  return (
    <Layout breadCrumb={breadC} homeCallback={setCurrentMarket} ref={modalRef} >
      <Helmet>
        <html lang="pt-BR" amp />
        <meta charSet="UTF-8" />
        <title>{`${lojas.nome} | Spani Atacadista`}</title>
        <meta name="description" content={`Ofertas do Spani Atacadista -
        Loja: ${lojas.estado} - ${lojas.municipio} - ${lojas.bairro} - ${lojas.logradouro} - Confira as ofertas da semana no Spani.`}/>
        <title> {lojas.nome} | Spani Atacadista</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="author" content="Spani Atacadista"/>
        <meta name="keywords" content='spani, ofertas, ofertas do spani' />
        <meta property="og:title" content="Ofertas da Semana | - Spani Atacadista" />
        <meta property = "og:description"
          content = {
          `Ofertas válidas para a loja ${lojas.logradouro} - ${lojas.municipio} - ${lojas.estado}.`
          }
        />
        <script type="application/ld+json">{`${JSON.stringify(localBusiness)}`}</script>
      </Helmet>
      {/* <Pagina pagina={data.pagina}/> */}
      <section className="container mt-4">
        <h1 className="mb-4">Ofertas</h1>
        <div className="row">
          <div className="col-12 d-flex justify-content-between">
            <div className="d-lg-flex d-block bottom_custom_black w-100 justify-content-between">
              {isExists() ? <h2 className="font-size-15 pb-4">Ofertas válidas até {getDate()} ou até durarem os estoques.</h2> : <h2 className="font-size-15 pb-4">Oferta não encontrada</h2>}
              <div>
                <img src={locationPin} alt="pin de localização da loja atual" className="mr-2" width="13" height="17" />
                <span className='font-weight-bold font-location'>{currentMarket ? currentMarket.nome : 'Selecione uma Loja'}&nbsp;&nbsp; | <u onClick={() => modalRef.current.modalHandler()}>Trocar de loja</u></span>
              </div>
            </div>
          </div>
          {
            offers.edges.map((off) => {
              console.log(off)
              return (
                <div className="col-lg-4 pt-4">
                  <GatsbyImage
                    image={off.node.capa.localFile.childImageSharp.gatsbyImageData}
                    alt="Capa da oferta" />
                  <div className="row pt-2">
                    <div className="col-6">
                      <button onClick={() => handleDownload(off.node.pdf.file.url, off.node.pdf.file.fileName)} className="btn btn-yellow d-flex align-items-center px-3 text-uppercase justify-content-center font-openSans-semibold w-100"><img src={icondownload} alt="icone de download" className="mr-2" />baixar</button>
                    </div>
                    <div className="col-6">
                      <a href={off.node.pdf.file.url} target="_blank" rel="noreferrer">
                        <button className="btn btn-yellow w-100 d-flex align-items-center px-3 text-uppercase justify-content-center font-openSans-semibold"><img src={iconvisualizar} alt="icone de visualizar" className="mr-2" />visualizar</button>
                      </a>
                    </div>
                  </div>
                </div>
              );
            })
          }
          <div className="col-12 mt-5 mb-5">
            <p className="font-size-08">As ofertas estão condicionadas ao estoque e os valores referem-se a preços unitários. Os preços anunciados são para pagamento à vista e somente para produtos retirados na própria loja. HORÁRIO DE FUNCIONAMENTO: Segunda a Sábado das 07h às 22h/ Domingo e Feriado sob consulta. As fotos e imagens são meramente ilustrativas, é possível que ocorra erros gráficos, qualquer dúvida consultar a gerência. O Ministério da Saúde adverte: O aleitamento materno evita infecções e alergias e é recomendado até os dois anos de idade ou mais. Após os seis meses de idade, continue amamentando seu filho e ofereça novos alimentos.</p>
          </div>
        </div>
      </section>
      <SectionBlogPost></SectionBlogPost>
    </Layout>
  );
}

export default Offer
export const query = graphql`query ($slug: String!, $tabloide: String!, $currentDate: Date!) {
  contentfulLojas(slug: {eq: $slug}) {
    nome
    bairro
    cep
    diaUtilAbre
    diaUtilFecha
    domingoAbre
    domingoFecha
    estado
    feriadoAbre
    feriadoFecha
    id
    idTarifa
    lat
    lng
    logradouro
    municipio
    numero
    slug
    telefone
    tabloideRef {
      nomeTabloide
    }
  }
  allContentfulTabloide(
    filter: {tabloideRef: {nomeTabloide: {eq: $tabloide}}, dataInicio: {lte: $currentDate}, dataFim: {gte: $currentDate}}
  ) {
    edges {
      node {
        tabloideRef {
          nomeTabloide
        }
        capa {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        pdf {
          file {
            url
            fileName
          }
        }
        dataInicio
        dataFim
      }
    }
  }
}
`
